export default {
  /** strings for accessibility features (i.e. VoiceOver) */
  accessibility: {
    types: {
      button: 'button',
      header: 'header',
    },

    refresh: 'Aktualisierung',
    refreshHint:
      'Holen Sie sich den Fragebogen und den Status neu.Die aktuelle Seite wird aktualisiert.',

    back: 'zurück',
    backHint: 'Springt zur vorherigen Seite.',

    close: 'nah dran',
    closeHint: 'Schließt die aktuelle Seite oder Modal.',

    accept: 'annehmen',
    cancel: 'Abbrechen',
    acceptHint:
      'Wechselt in die Standard-Browser-Anwendung und öffnet dort Link.',

    menu: 'Weitere Informationen',
    menuHint: 'Öffnet neue Seite mit zusätzlichen Informationen und Links.',

    logoutHint: 'Startet den Abmeldeprozess und umleiten die Anmeldeseite.',
    loginHint: 'Startet Anmeldevorgang und leitet den QR-Code-Scanner um.',

    questionnaire: {
      middleButtonFinished: 'Nächste Frage. Frage wird beantwortet.',
      middleButtonUnfinished:
        'Nächste Frage, obwohl die Frage noch nicht beantwortet wird.',
      middleButtonHint:
        'Springt zu den nächsten Fragen oder schließt das Modal, wenn dies die letzte Frage war.',

      rightButtonHint: 'Springt zur nächsten Frage.',
      leftButtonHint: 'Springt zur vorherigen Frage.',
      alertButtonHint: 'Schließt den Alarm.',

      questionnaireCellHint:
        'Öffnet neue Seite, um die Kategorien der Fragebogen anzuzeigen.',
      categoryCellHint:
        'Öffnet MODAL, um diesen Fragebogeneintrag auszufüllen.',
      category: 'Diese Kategorie',
      questionnaire: 'Dieser Fragebogen',
      notStarted: 'wurde noch nicht beantwortet.',
      notFinished:
        'wurde bereits gestartet, ist aber noch nicht vollständig ausgefüllt.',
      finished: 'ist vollständig vollständig.',

      sendHint: 'Sendet den Fragebogen an die Bewertung.',

      multipleChoice:
        '(Mehrfachauswahl möglich)',
      singleChoice:
        'Einzelne Wahl - Nur eine Antwort-Option kann ausgewählt werden.',
      textFieldHint:
        'Bitte füllen Sie hier die angeforderten Informationen aus.',
      dateFieldHint: 'Öffnet ein neues Modal, um ein Datum auszuwählen.',
      sliderFieldEquals: 'entspricht',
      sliderFieldAnd: ', und',

      triggerHint:
        'Wenn dieses Element ausgewählt ist, wird eine weitere zusätzliche Frage angezeigt, die mit diesem Element verbunden ist.Bitte füllen Sie auch die zusätzliche Frage aus.',
      expandCategory: 'Fragen dieser Kategorie anzeigen',
      collapseCategory: 'Fragen dieser Kategorie ausblenden',

      requiredNotAnswered: 'Sie haben diese Frage noch nicht beantwortet.',
      warningNotAnswered: 'Sie haben diese Frage noch nicht beantwortet. Sind Sie sicher, dass Sie zur nächsten Frage übergehen möchten?',
      warningNotAnsweredTitle: 'Keine Antwort gegeben',
      warningAnswerNow: 'Frage jetzt beantworten',
      warningSkip: 'Frage überspringen',
      continueFromLastTitle: 'Fortfahren',
      continueFromLast: 'Sie haben zuletzt mit der Beantwortung der Fragen abgebrochen. Möchten Sie mit der Frage fortfahren, bei der Sie zuletzt stehen geblieben sind?',
      continueFromLastYes: 'Jetzt fortfahren',
      continueFromLastNo: 'Abbrechen',
    },
  },

  /** contains all string rendered on the login-screen, as well as the ones for the landing-screen */
  login: {
    title: 'Anmeldung Umfragetool UK Bonn',
    subTitle: '',
    submit: 'Anmeldung',
    user: 'Subjekt-ID.',
    pass: 'Passwort',
    waiting: 'Anmelden...',
    errorPass: 'Bitte versuche es erneut.',
    noSubjectId: 'Kein gültiges SubjektD gefunden',
    errorUserUnauthorized: 'Ungültige Benutzerdaten.',
    qrInfo: 'Bitte zeigen Sie nun die Kamera auf den QR-Code den Sie von Ihrer Studienleitung zur Anmeldung in der App bekommen haben.',
    errorUserGeneric: 'Bei der Anmeldung trat ein Problem auf.',
    permissionDialog:
      'Bitte erlauben Sie der App, auf Ihre Kamera zuzugreifen.',
    nextStepAfterError: 'Bitte versuchen Sie es später erneut anzumelden.',

    /** contains all strings of the landing-screen */
    landing: {
      buttonText: 'Navigieren Sie zum Anmeldebildschirm',
      title: '',
      subTitle: '',
      welcomeTitle: 'Willkommen beim Umfragetool UK Bonn',
      text: 'Sie haben von der Studienleitung für die Studie in der Sie teilnehmen einen QR-Code zur Anmeldung in der App erhalten. Bitte melden Sie sich nun mit dem erhaltenen QR-Code in der App an.',
      autoLoginErrorTitle: 'Auto-Anmeldung fehlgeschlagen',
      autoLoginError:
        'Beim Versuch, sich automatisch anzumelden, ist ein Fehler aufgetreten.Bitte überprüfen Sie Ihr Netzwerk und versuchen Sie es erneut',
      retry: 'wiederholen',
      deleteAll: 'Alle lokal gespeicherten Daten löschen',
    },
  },

  walkthrough: {
    k1: {
      title: 'Willkommen beim Umfragetool UK Bonn',
      text: 'Mit der Teilnahme an der Studie haben Sie der Beantwortung eines oder mehrerer Fragebögen zugestimmt. \n\n' +
          'Nach erfolgreicher Anmeldung beim Umfragetool finden Sie auf der Startseite eine Übersicht über die für Sie verfügbaren Fragebögen. ',
    },
    k2: {
      title: 'Beantworten eines Fragebogens',
      text: 'Bei der Beantwortung des Fragebogens muss jede Angabe über den „Weiter“-Button bestätigt werden. \n\n' +
          'Die Beantwortung eines Fragebogens kann jederzeit pausiert und zu einem späteren Zeitpunkt fortgesetzt werden. Die gegebenen Antworten werden auf dem Gerät gespeichert. Darüber hinaus werden bereits beantwortete Fragen farblich (blau) gekennzeichnet. ',
    },
    k3: {
      title: 'Absenden eines Fragebögen',
      text: 'Ein Fragebogen enthält unter anderem sogenannte „Pflichtfragen“, die als solche gekennzeichnet sind. Ein Fragebogen kann grundsätzlich nur abgeschickt werden, wenn jede dieser Pflichtfragen beantwortet wurden. Nach dem Absenden des Fragebogens können die ausgewählten oder angegebenen Antworten nicht mehr verändert werden. ',
    },
    k4: {
      title: 'Verfügbare Fragebögen',
      text: 'Beachten Sie, dass im Laufe der Studie gegebenenfalls weitere Fragebögen für Sie zum Ausfüllen bereitgestellt werden. In diesem Fall werden Sie durch eine Benachrichtigung informiert. \n\n' +
          'Aufgrund dessen bitten wir Sie darum, sich erst nach Beendigung der Studie von der App abzumelden beziehungsweise diese zu deinstallieren. ',
    }
  },

  /** strings with generic purpose that can be used throughout the application */
  generic: {
    questionnaire: 'Fragebogen',
    no: 'Nein',
    yes: 'Ja',
    ok: 'OK',
    choose: 'Auswählen',
    error: 'Fehler',
    abort: 'Abbrechen',
    warning: 'Warnung',
    info: 'Information',
    errorTitle: 'Fehler',
    successTitle: 'Erfolg',
    delete: 'Ja, Daten löschen',
    goBack: 'Ja, bitte melden Sie sich an',
    logoutWarning: 'Abmeldung Warnung',
    sendSuccess: 'Ihre Antworten wurden erfolgreich abgeschickt.',
    sendError:
      'Bei der Datenübermittlung ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie den Administrator.',
    sendErrorTwoDevices:
      'Sie haben diesen Fragebogen bereits durch die App an einem anderen Gerät gesendet.',
    eraseAllWarning:
      'Dadurch werden alle auf Ihrem Gerät gespeicherten Daten gelöscht und Sie werden abgemeldet. Möchten Sie fortfahren?',
    eraseLocalDataAtEndOfStudyText:
      'Dies wird die Studie beenden. Sobald der Benutzer die lokalen Daten bestätigt hat, wird der Teilnehmer abgemeldet. Möchten Sie fortfahren?',
    eraseLocalDataAtEndOfStudyTitle: 'Lokal gespeicherte Daten löschen und abmelden',
    wrongLanguageVersionDetected:
      'Der aktuelle Fragebogen stimmt nicht mit der aktuell ausgewählten Sprache auf Ihrem Gerät überein. Dieser Fragebogen wird nun durch eine passende Version ersetzt. Ihre Antworten gehen dabei verloren.',
    updateError:
      'Beim Erhalten der angeforderten Daten ist ein Fehler aufgetreten - bitte prüfen Sie Ihre Daten und versuchen Sie es erneut.',
    infoRemoval:
      'Der derzeit aktuelle Fragebogen ist veraltet und wird nun entfernt.',
    reportWhileInIteratedMode:
      'Dialog, um dem Benutzer mitzuteilen, dass er bereits in einem speziellen Bericht gesendet wurde und dass er gerade in einem speziellen Intervall ist.',
    reportWhileQuestionnaire:
      'Dialog, um dem Benutzer mitzuteilen, dass derzeit ein Fragebogen zur Verfügung steht und dass er das verwenden könnte, um über seinen Zustand zu berichten.',
    errorNoInternetTitle: 'Keine aktive Internetverbindung gefunden.',
    errorNoInternet: 'Bitte aktivieren Sie Ihre Internetverbindung, sorgen Sie für eine gute Signalstärke und versuchen Sie es erneut.'
  },

  /** strings of the about-screen */
  about: {
    title: 'Über',
    subTitle: 'Über das Umfragetool UK Bonn',
    logout: 'Abmelden',
    delete: 'Meine Daten löschen und Abmelden',
    demoDelete: 'Alles löschen & Abmelden (nur für diese Demo)',
    languageSelection: 'Sprachauswahl',
    languageWarning:
      'Bei Änderung der Sprachauswahl werden Ihre bisher eingegebenen Daten vom Gerät gelöscht.',
    languageWarningAddition: '\nWillst du fortfahren?',

    /** contains the strings for the optional LegalInformation Screen */
    legal: {
      title: 'Datenschutzerklärung',
      subTitle:
          'Wie wir Ihre Daten behandeln',
      iconType: 'entypo',
      iconTitle: 'suitcase',
    },
    intro: {
      title: 'Einführung',
      subTitle:
          'Kurze Einführung in die App anzeigen',
      iconType: 'entypo',
      iconTitle: 'help-with-circle',
    },
    bleDevices: {
      title: 'BLE Geräte',
      subTitle:
          'Unterstützte Geräte koppeln',
      iconType: 'entypo',
      iconTitle: 'help-with-circle',
    },
  },

  /** contains the strings for the legal information screen */
  legalInformation: {
    title: 'Nutzungsbedingungen',
    subTitle: '',

    content:
      'Nutzungsbedingungen'
  },

  /** strings of the survey-screen */
  survey: {
    subTitle: 'test',
    logout: 'Abmelden',
    subTitleCheckIn: '',
    title: 'Fragebögen',
    isLoading: 'Wird geladen...',
    yourAnswer: 'Ihre Antwort',
    titleCheckIn: 'Umfragetool UK Bonn',
    welcomeTitle: 'Willkommen',
    noUserTitle: 'Benutzer wurde nicht gefunden',
    sendFinished: 'Antworten abschicken',
    send: 'Fragebogen abschicken',
    additionalAnswer: 'zusätzlicheAntwort',
    alternativeAnswer: 'alternativeAntwort',
    surveySubTitle: 'Bitte ausfüllen bis zum: ',
    surveyTitle: 'Befragung starten',
    surveyTitleContinue: 'Befragung fortsetzen',
    inputPlaceholder: 'Bitte gib deine Antwort ein',
    endedStudyTitle: 'Ende des Teilnahme-Titels',
    noQuestionnaireTitle: 'Fragebogen nicht gefunden',
    loadingQuestionnaire: 'Anfragen \n Questionnaire.',
    inputPlaceholderTime: 'Bitte geben Sie das Datum hier ein',
    sessionTimeout: 'Ihr Benutzer konnte nicht erkannt werden',
    reloadingQuestionnaire: 'Wiederverladung \n Questionnaire',
    welcomeTitleFirstTime: 'Studienteilnahme',
    surveyTitleFirstTime: 'Ihr Einstiegs-Fragebogen-Titel',
    noUserText:
      'Ihre ID konnte nicht überprüft werden.Bitte versuche es erneut.',
    welcomeTextFirstTimeUser2:
      '- Dies ist der zweite Teil dieser Nachricht.',
    sendFinishedMessage:
      'Bitte bestätigen Sie, dass Sie die Antworten nun abschicken möchten.',
    noNewQuestionnaireAvailableYetTitle:
      'Titel, falls kein Fragebogen verfügbar ist',
    sendUnfinishedMessageDenied:
      'Der aktuelle Fragebogen ist noch nicht abgeschlossen.Bitte tun Sie dies.',
    endedStudyText:
      'Diese Zeichenfolge enthält Informationen zum Ende der Studie für den Benutzer.',
    nextOne:
      'Dieser Text wird angezeigt, um zu kommunizieren, wenn der nächste Fragebogen verfügbar ist: ',
    noQuestionnaireText:
      'Beim Laden des Fragebogens trat ein Fehler auf - bitte versuchen Sie es erneut.',
    noNewQuestionnaireAvailableYet:
      'Aktuell ist kein Fragebogen für Sie verfügbar. Wir bedanken uns für Ihre Teilnahme an der Studie.',
    nextOneNew:
      'Aktuell ist kein Fragebogen für Sie verfügbar. Der nächste Fragebogen wird am folgenden Tag für Sie verfügbar gemacht: ',
    furtherInfo:
      'Wir informieren Sie, sobald ein weiterer Fragebogen verfügbar wird.',
    welcomeTextUser:
      'Eine Umfrage ist aktuell für Sie zum Ausfüllen verfügbar. Bitte beantworten Sie die Fragen bis zum angegebenen Zeitpunkt aus:',
    welcomeTextFirstTimeUser1:
      'Wir informieren Sie, sobald ein weiterer Fragebogen verfügbar wird. ',
    invalidInteger: 'Nur ganzzahlige Werte erlaubt!',
    invalidDecimal: 'Keine gültige Dezimalzahl!',
    invalidTooBig: 'Wert muss kleiner sein als:',
    invalidTooSmall: 'Wert muss größer sein als:',
    invalidNotEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    notMatchingPattern: 'Eingabe entspricht nicht den Vorgaben!',
  },

  /** strings for the special-report-flow */
  reporting: {
    symptoms_no: 'NEIN',
    symptoms_yes: 'JAWOHL',
    symptoms_success_ok: 'Erfolgsbestätigung',
    symptoms_success_header: 'Erfolgs-Nachrichten-Header',
    symptoms_header: 'Sonderbericht schicken',
    symptoms_success_message: 'Erfolgsnachricht-Inhalt',
    symptoms_question: 'Bestätigungsfrage hier einfügen',
  },

  /** each entry in this array will generate a new listItem on the about-screen. When clicked on such an item, a webView will open that
   * can display any website. "iconType" is the name of a free Webfont and the title is the name of the particular icon. .
   * as this app is using react-native-elements, the list of compatible fonts can be found in its documentation: * https://reactnativeelements.com/docs/icon
   */
  webViews: [
    {
      title: 'Impressum',
      subTitle:
          ' ',
      screenSubTitle: '',
      uri: 'https://www.ukbonn.de/impressum/',
      iconTitle: 'info',
      iconType: 'entypo',
    },
    {
      title: 'Datenschutzerklärung',
      subTitle:
        ' ',
      screenSubTitle: '',
      uri: 'https://www.ukbonn.de/datenschutzerklaerung/',
      iconTitle: 'suitcase',
      iconType: 'entypo',
    },
    {
      title: 'Nutzungsbedingungen',
      subTitle:
        ' ',
      screenSubTitle: '',
      uri: 'https://umfragetool.digital-medicine.org/terms',
      iconTitle: 'suitcase',
      iconType: 'entypo',
    },
    {
      title: '',
      subTitle:
        ' ',
      screenSubTitle: '',
      uri: '',
      iconTitle: '',
      iconType: '',
    }
  ],

  /** each entry in this array will generate a new listItem on the about-screen. When clicked on such an item, a modal will open
   * that informs the user that he/she is being redirected to their local browser. "iconType" is the name of a free Webfont and the title is the name of the particular icon. .
   * as this app is using react-native-elements, the list of compatible fonts can be found in its documentation: * https://reactnativeelements.com/docs/icon
   */
  modalLinks: [
    // {
    //   title: 'Erster Link',
    //   subTitle:
    //     'Dies ist ein regulärer Link.Es wird ein Modal eröffnet, bevor der Benutzer den Benutzer auf seinen eigenen Browser umleitet.',
    //   text: 'Ihre werden jetzt umgeleitet.',
    //   uri: 'https://github.blog/',
    //   iconTitle: 'link',
    //   iconType: 'entypo',
    // },
    // {
    //   title: 'Zweiter Link',
    //   subTitle:
    //     'Für jeden Artikel fügen Sie der Eigenschaft Modulks von textconfig.js hinzu, ein anderer Eintrag in dieser Liste wird generiert.',
    //   text: 'Ihre werden jetzt umgeleitet.',
    //   uri: 'https://ibm.com/',
    //   iconTitle: 'link',
    //   iconType: 'entypo',
    // },
  ],
};
