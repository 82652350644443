import { reduxStore } from '../../store';
import { showAlert, hideAlert } from '../../store/alert.slice';

const AlertModal = {
    alert: (title, message, alertButtons) => {
        reduxStore.dispatch(showAlert({ title, message, alertButtons }));
    },
    hide: () => {
        reduxStore.dispatch(hideAlert());
    },
};

export default AlertModal;
