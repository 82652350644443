import React, {useEffect, useState} from 'react';
import {
    Dimensions,
    Picker,
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {itemPropType} from '../../../propTypes';

// components
import {Button} from '@rneui/themed';

// redux actions
import {setAnswer} from '../../../store/questionnaire.slice';

// services & config
import {theme} from '../../../config';
import exportService from '../../../services/questionnaireAnalyzer';
import translate from '../../../services/localization';

import SharedStyles from './sharedStyles';
import RenderHtml from "react-native-render-html";
import ModalInfo from "../../../components/questionnaireModal/modalInfo";
import {markdownToHtml, stripTags, yyyymmddToDate} from "../../../services/utils";
import debounce from "lodash.debounce";
import NumericInput from "react-numeric-input";
import {DatePickerModal} from 'react-native-paper-dates';
import {TextInput} from "react-native-paper";
import {SafeAreaView} from "react-native-safe-area-context";
import DeviceInfo from "react-native-device-info";
import moment from "moment";

/***********************************************************************************************
 * component
 * renders a questionnaire item as date input
 *
 * @param {object} props
 * @param {QuestionnaireItem} props.item the item to be rendered
 **********************************************************************************************/

/**
 * debounce the update of the global state for slightly better performance;
 * instead of dispatching an action after each keystroke, we wait for 350ms
 * should another keystroke occur before 350ms have passed, the previous actions is interrupted
 * and a new action with the updated parameters is enqueued
 *
 */
const setGlobalAnswer = debounce((item, retVal, dispatch) => {
    dispatch(setAnswer({answer: retVal, linkId: item.linkId}));
}, 150);

export default function DateInput({item, handleForwardPress}) {
    // internal state which controls, whether the datepicker is shown
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [localValue, setLocalValue] = useState(null);
    const { fontScaleFactor } = useSelector(
        (state) => state.Globals,
    );

    const {itemMap, questionnaireItemMap} = useSelector(
        (state) => state.Questionnaire,
    );

    const minDate = item.minVal
        ? item.minVal.includes('now') || item.minVal.includes('today')
            ? new Date()
            : yyyymmddToDate(item.minVal)
        : null;
    const maxDate = item.maxVal
        ? item.maxVal.includes('now') || item.maxVal.includes('today')
            ? new Date()
            : yyyymmddToDate(item.maxVal)
        : null;
    if (maxDate) {
        maxDate.setHours(23);
        maxDate.setMinutes(59);
        maxDate.setSeconds(59);
    }

    const completed = useSelector((state) => {
        return state.Questionnaire.itemMap[item.linkId].done;
    });

    // get currentValue from state
    const globalValue = useSelector((state) => {
        if (item.type == 'time') {
            return state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueTime;
        }
    });

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    // when the component is updated get current value from global state if local value does not exist
    useEffect(
        () => setLocalValue(localValue ?? globalValue),
        [localValue, globalValue],
    );

    // const [showTimePicker, setShowTimePicker] = useState(false);
    const dispatch = useDispatch();
    // get currentDate from state
    console.log(item.type);
    let initDate = '';
    let initHour = 0;
    let initMinute = 0;
    let currentDate;
    if (item.type == 'date') {
        currentDate = useSelector(
            (state) => {
                return state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueDate ?? new Date();
            }
        );
        if (currentDate) {
            initDate = !!currentDate ? new Date(Date.parse(currentDate)) : new Date();
            console.log(initDate);
            console.log('initDate');
        }
    }
    if (item.type == 'time') {
        currentDate = useSelector(
            (state) =>
                state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueTime,
        );
        if (currentDate) {
            initHour = parseInt(currentDate.split(':')[0]);
            initMinute = parseInt(currentDate.split(':')[1]);
        }
    }
    if (item.type == 'datetime') {
        currentDate = useSelector(
            (state) =>
                state.Questionnaire.itemMap[item.linkId].answer?.[0]?.valueDateTime,
        );
        if (currentDate) {
            const parts = currentDate.substring(0, 10).split('-'); // Split the string into parts

            if (parts.length !== 3) {
                throw new Error('Invalid date format');
            }

            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-indexed
            const day = parseInt(parts[2], 10);

            initDate = new Date(year, month, day);

            let initTime = currentDate.split(' ');
            if (initTime.length == 2) {
                initHour = parseInt(initTime[1].split(':')[0]);
                initMinute = parseInt(initTime[1].split(':')[1]);
            } else {
                initHour = 0;
                initMinute = 0;
            }
        } else {
            initDate = new Date();
            initHour = initDate.getHours();
            initMinute = initDate.getMinutes();
        }
    }

    const [localHour, setLocalHour] = useState(initHour);
    const [localMinute, setLocalMinute] = useState(initMinute);
    const [localDate, setLocalDate] = useState(new Date(Date.parse(initDate)));
    console.log(typeof localDate);
    console.log("LocalDate: ", initDate);

    const {width} = useWindowDimensions();
    const source = {
        html:
            '<div style="font-weight: bold; font-size: ' +
            fontScaleFactor * (DeviceInfo.isTablet() ? 1.75 : 1.2)  +
            'em">' +
            markdownToHtml(item.text) +
            '</div>',
    };

    let hourHint = 'Uhr';
    let minuteHint = 'Min.';
    let maxHour = 23;

    console.log('item.fieldAnnotation');
    console.log(item.fieldAnnotation);
    if (item.type == 'time') {
        if (item.fieldAnnotation.includes('[validtime|MM:SS')) {
            hourHint = 'Min.';
            minuteHint = 'Sek.';
            maxHour = 59;
        }
    }

    let calculateAnswer = (date: Date, time: string) => {
        // console.log('calculateAnswer');

        let errorMsg = '';

        let ret = null;
        if (item.type == 'time' && time) {
            if (item.fieldAnnotation.includes('[validduration')) {
                const regex = /\[validduration(max|min)*\|(.+?)\|(.+?)]/;
                const matches = item.fieldAnnotation.match(regex);

                let origCode = '';
                let limit = '';
                let maxOrMin = 'max';
                if (matches) {
                    if (matches[1]) {
                        maxOrMin = matches[1];
                    }
                    origCode = matches[2];
                    limit =
                        matches[3] === 'now' || matches[3] === 'today'
                            ? new Date()
                            : yyyymmddToDate(item.maxVal);
                    limit.setHours(moment().hours());
                    limit.setMinutes(moment().minutes());
                    limit.setSeconds(moment().seconds());
                }

                let baseDate = '';
                for (const k in itemMap) {
                    if (itemMap[k].origCode === origCode) {
                        baseDate = itemMap[k].answer?.[0]?.valueDateTime;
                    }
                }

                let op1 = limit;
                let t = time.split(':');

                let op2 = moment(baseDate).add(t[0], 'minute').add(t[1], 'second');
                if (maxOrMin === 'max') {
                    if (!moment(op1).isSameOrAfter(op2)) {
                        errorMsg +=
                            'Die aufaddierte Eingabe darf höchstens ' +
                            moment(limit).format('D.M.Y LT') +
                            ' sein.\n';
                    }
                } else {
                    if (!moment(op1).isSameOrBefore(op2)) {
                        errorMsg +=
                            'Die aufaddierte Eingabe darf höchstens ' +
                            moment(limit).format('D.M.Y LT') +
                            ' sein.\n';
                    }
                }
            }

            ret = {valueTime: time};
        } else if (item.type == 'date' && date) {
            if (!!minDate && minDate > date) {
                errorMsg +=
                    'Die Eingabe muss mindestens ' +
                    moment(minDate).format('LT') +
                    ' sein.\n';
            }
            if (!!maxDate && maxDate < date) {
                console.log('maxDate');
                console.log(maxDate);
                errorMsg +=
                    'Die Eingabe darf höchstens ' +
                    moment(maxDate).format('LT') +
                    ' sein.\n';
            }
            ret = {
                valueDate: !errorMsg ? exportService.getFormattedDate(date) : null,
            };
        } else if (item.type == 'datetime' && date) {
            date.setHours(localHour);
            date.setMinutes(localMinute);
            if (!!minDate && minDate > date) {
                errorMsg +=
                    'Die Eingabe muss mindestens ' +
                    moment(minDate).format('LLL') +
                    ' sein.\n';
            }
            if (!!maxDate && maxDate < date) {
                errorMsg +=
                    'Die Eingabe darf höchstens ' +
                    moment(maxDate).format('LLL') +
                    ' sein.\n';
            }
            ret = {
                valueDateTime: !errorMsg
                    ? exportService.getFormattedDate(date) +
                    ' ' +
                    zeroPad(localHour, 2) +
                    ':' +
                    zeroPad(localMinute, 2)
                    : null,
            };
        } else if (item.type == 'datetime' && time) {
            console.log('KKK1');
            let h = parseInt(time.split(':')[0]);
            let m = parseInt(time.split(':')[1]);
            localDate.setHours(h);
            localDate.setMinutes(m);
            if (!!minDate && minDate > localDate) {
                errorMsg +=
                    'Die Eingabe muss mindestens ' +
                    moment(minDate).format('LLL') +
                    ' sein.\n';
            }
            console.log('KKK3', maxDate, localDate);
            if (!!maxDate) {
                maxDate.setHours(moment().hours());
                maxDate.setMinutes(moment().minutes());
                maxDate.setSeconds(moment().seconds());
                if (maxDate < localDate) {
                    console.log('maxDate');
                    console.log(maxDate);
                    errorMsg +=
                        'Die Eingabe darf höchstens ' +
                        moment(maxDate).format('LLL') +
                        ' sein.\n';
                }
            }
            ret = {
                valueDateTime: !errorMsg
                    ? exportService.getFormattedDate(localDate) + ' ' + time
                    : null,
            };
        } else if (item.type == 'time') {
            ret = {
                linkId: item.linkId,
                answerTime: null,
            };
        } else if (item.type == 'date') {
            ret = {
                linkId: item.linkId,
                answerDate: null,
            };
        } else if (item.type == 'datetime') {
            ret = {
                linkId: item.linkId,
                answerDateTime: null,
            };
        }

        setErrorMsg(errorMsg);

        return ret;
    };

    // for DatePickerModal
    //   const [date, setDate] = React.useState(new Date());
    const onDismissSingle = React.useCallback(() => {
        setShowDatePicker(false);
    }, [setShowDatePicker]);

    const onConfirmSingle = React.useCallback(
        (params) => {
            setShowDatePicker(false);
            //   setDate(params.date);
            if (params.date) {
                setLocalDate(params.date);
                dispatch(
                    setAnswer({
                        linkId: item.linkId,
                        answer: calculateAnswer(params.date, null),
                    }),
                );
            }
        },
        [setShowDatePicker],
        // [setDate],
        [setLocalDate],
    );

    // params for custom Month Picker for web
    const months = [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
    ];
    const years = Array.from(
        {length: 150},
        (_, k) => new Date().getFullYear() - k,
    );
    const [selectedMonth, setSelectedMonth] = useState(
        months[localDate.getMonth()],
    );
    const [selectedYear, setSelectedYear] = useState(localDate.getFullYear());
    const [monthPickerItems, setMonthPickerItems] = useState(months);

    useEffect(() => {
        if (selectedYear == new Date().getFullYear()) {
            setMonthPickerItems(months.slice(0, new Date().getMonth() + 1));
        } else {
            setMonthPickerItems(months);
        }
    }, [selectedYear]);

    const dispatchDate = () => {
        let date = new Date(selectedYear, months.indexOf(selectedMonth));
        console.log('dispatchDate', months.indexOf(selectedMonth), ' ', date);
        if (!date) {
            return;
        }
        setLocalDate(date);
        dispatch(
            setAnswer({
                linkId: item.linkId,
                answer: calculateAnswer(date, null),
            }),
        );
    }

    // useEffect(() => {
    //     if (showMonthYearPicker) {
    //         dispatchDate();
    //     }
    // }, [selectedMonth, selectedYear, showMonthYearPicker])

    const datePress = () => {
        if (item.fieldAnnotation.includes('[mmyyyy]')) {
            setShowMonthYearPicker(true);
        } else {
            setShowDatePicker(true);
        }
    }

    return (
        <>
            <View style={SharedStyles.modalInput}>
                {/* title */}
                {Platform.OS === 'web' ? (<div
                        dangerouslySetInnerHTML={{__html: source.html}}
                    />) :
                    (<RenderHtml
                        contentWidth={width}
                        source={source}
                    />)
                }

                {!!item.fieldAnnotation && !!stripTags(item.fieldAnnotation) && (
                    <ModalInfo infoText={stripTags(item.fieldAnnotation)}/>
                )}

                {/* android datepicker */}

                <SafeAreaView>
                    {(item.type == 'date' || item.type == 'datetime') && (
                        <TouchableOpacity
                            onPress={datePress}
                            // accessibilityLabel={ }
                            // accessibilityRole={translate('accessibility').types.button}
                            // accessibilityHint={translate('accessibility').questionnaire.dateFieldHint}
                            testID="overlay"
                        >
                            {!showMonthYearPicker &&
                            <TextInput
                                containerStyle={SharedStyles.modalContainer}
                                placeholder={translate('login').inputPlaceholderTime}
                                value={
                                    (localDate && completed)
                                        ? exportService.getFormattedDate(
                                            localDate,
                                            item.fieldAnnotation.includes('[mmyyyy]')
                                                ? 'mmyyyy'
                                                : true,
                                        )
                                        : "Bitte Auswahl tätigen"
                                }
                                style={{
                                    textAlign:
                                        item.type == 'integer' || item.type == 'decimal'
                                            ? 'right'
                                            : 'left',
                                    backgroundColor: theme.colors.primary_light,
                                    marginTop: 15,
                                    padding: (fontScaleFactor - 1) > 0 ? 10 * (fontScaleFactor - 1) : 0,
                                    fontSize: 20 * fontScaleFactor
                                }}
                                editable={false}
                                left={<TextInput.Icon onPress={datePress} icon="calendar" />}
                                pointerEvents="none"
                                testID="chosenDate"
                            />}
                        </TouchableOpacity>
                    )}
                    {showMonthYearPicker && (
                        <View style={{flexDirection: 'row', paddingTop: '5px'}}>
                            <Picker
                                style={{marginRight: '5px'}}
                                selectedValue={selectedMonth}
                                onValueChange={(month) => setSelectedMonth(month)}
                            >
                                {monthPickerItems.map((month, index) => {
                                    return (
                                        <Picker.Item key={index} label={month} value={month}/>
                                    );
                                })}
                            </Picker>
                            <Picker
                                style={{marginRight: '5px'}}
                                selectedValue={selectedYear}
                                onValueChange={(year) => setSelectedYear(year)}
                            >
                                {years.map((year, index) => {
                                    return <Picker.Item key={index} label={year} value={year}/>;
                                })}
                            </Picker>
                            <Button
                                buttonStyle={{backgroundColor: theme.colors.primary}}
                                onPress={() => {
                                    setShowMonthYearPicker(false)
                                    dispatchDate();
                                }}
                                title="Bestätigen"
                            />
                        </View>

                        // <MonthPickerWeb
                        //     onChange={(event, date) => {
                        //         setShowMonthYearPicker(false);
                        //         if (!date) {
                        //             return;
                        //         }

                        //         setLocalDate(date);
                        //         dispatch(
                        //             setAnswer({
                        //                 linkId: item.linkId,
                        //                 answer: calculateAnswer(date, null),
                        //             }),
                        //         );
                        //     }}
                        //     value={localDate ? localDate : new Date()}
                        //     minDate={minDate}
                        //     maxDate={maxDate}
                        // />
                    )}
                </SafeAreaView>

                {(item.type == 'time' || item.type == 'datetime') && (
                    <>
                        <View
                            style={{
                                flexDirection: 'row',
                                marginTop: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <NumericInput
                                onChange={(value) => {
                                    setLocalHour(value);
                                    let hour = zeroPad(value, 2);
                                    let minute = zeroPad(localMinute, 2);
                                    let time = hour + ':' + minute;
                                    dispatch(
                                        setAnswer({
                                            linkId: item.linkId,
                                            answer: calculateAnswer(null, time),
                                        }),
                                    );
                                }}
                                min={0}
                                max={maxHour}
                                value={localHour}
                                strict={true}
                            />
                            <Text> {hourHint} </Text>
                            {/*</View>*/}
                            {/**/}
                            {/*<View style={{*/}
                            {/*    'flexDirection': 'row',*/}
                            {/*    'marginTop': 20,*/}
                            {/*    'justifyContent': 'center',*/}
                            {/*    'alignItems': 'center'*/}
                            {/*}}>*/}
                            <NumericInput
                                onChange={(value) => {
                                    setLocalMinute(value);
                                    let hour = zeroPad(localHour, 2);
                                    let minute = zeroPad(value, 2);
                                    let time = hour + ':' + minute;
                                    dispatch(
                                        setAnswer({
                                            linkId: item.linkId,
                                            answer: calculateAnswer(null, time),
                                        }),
                                    );
                                }}
                                min={0}
                                max={59}
                                value={localMinute}
                                strict={true}
                            />
                            <Text> {minuteHint}</Text>
                        </View>
                    </>
                )}

                {showDatePicker && (
                    <SafeAreaView>
                        <View
                            style={{
                                justifyContent: 'center',
                                flex: 1,
                                alignItems: 'center',
                            }}
                        >
                            <DatePickerModal
                                locale="de"
                                mode="single"
                                visible={showDatePicker}
                                onDismiss={onDismissSingle}
                                date={localDate ? localDate : new Date()}
                                onConfirm={onConfirmSingle}
                                validRange={{startDate: minDate, endDate: maxDate}}
                                saveLabel="Speichern"
                                label="Datum auswählen"
                            />
                        </View>
                    </SafeAreaView>
                )}

                <Text
                    style={{
                        color: theme.colors.no,
                        fontWeight: 'bold',
                        marginTop: 10,
                        fontSize: fontScaleFactor * 16
                    }}
                >
                    {errorMsg}
                </Text>
            </View>
        </>
    );
}

DateInput.propTypes = {
    item: PropTypes.shape(itemPropType).isRequired,
};


/***********************************************************************************************
 localStyle
 ***********************************************************************************************/

const modalWidth = Dimensions.get('window').width - 40;

const localStyle = StyleSheet.create({
    dateTimePickerButtonBar: {
        flexWrap: 'nowrap',
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
        paddingRight: 20,
    },

    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: '100%'
    },


    maskedInput: {
        borderWidth: 1,
        borderRadius: 6,
        width: '80%',
        marginTop: 8,
        padding: 12,
        color: 'black',
        fontSize: 20
    },

    dateTimePickerButton: {
        paddingRight: 40,
    },

    iOSButton: {
        color: theme.colors.accent4,
        borderWidth: 1,
        borderRadius: 6,
        borderColor: theme.colors.accent4,
        padding: 15,
        fontSize: 22
    },

    iOSButtonOK: {
        color: theme.colors.white,
        backgroundColor: theme.colors.yes
    },
});
