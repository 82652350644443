import {Alert, Platform} from 'react-native';
import Storage from '../components/shared/Storage';
import {createAction, createAsyncThunk} from '@reduxjs/toolkit';

import {loggedInClient} from '../services/rest';
import translate from '../services/localization';
import kioskApi from '../config/kioskApiConfig';
import PushNotification from '../components/shared/PushNotification';
import RNFS from '../components/shared/RNFS';
import {appConfig} from "../config";
import AlertModal from "../components/shared/AlertModal";

const isKioskMode = kioskApi.active;

/**
 * shared actions used by multiple slices of the state
 */

const hideModal = createAction('shared/HIDE_MODAL');

const sendQuestionnaireResponse = createAsyncThunk(
    'shared/SEND_QUESTIONNAIRE_RESPONSE',
    async ({body, triggerMap, instrument}, thunkApi) => {
        const {
            User: {
                subjectId,
                current_questionnaire_id,
                current_instance_id,
                certificate,
                thankyou_text,
            },
        } = thunkApi.getState();
        try {
            console.log('send questionnaire response...')
            console.log('instrument')
            console.log(instrument);
            // send out response
            const response = await (isKioskMode
                ? kioskApi.sendQuestionnaire()
                : loggedInClient.sendQuestionnaire(
                    body,
                    triggerMap,
                    subjectId,
                    current_questionnaire_id,
                    current_instance_id,
                    certificate,
                ));
            AlertModal.alert(translate('generic').successTitle, thankyou_text ?? translate('generic').sendSuccess);
            console.log('PRE fullfillWithvalue')
            return thunkApi.fulfillWithValue( { 'response': response, 'instrument_name': instrument });
            console.log('POST fullfillWithvalue')
        } catch (err) {
            AlertModal.alert(
                translate('generic').errorTitle,
                translate('generic').sendError,
                [
                    {
                        text: translate('generic').ok,
                    },
                ],
                {cancelable: false},
            );
            return thunkApi.rejectWithValue({
                error: {
                    code: err.code ?? 'ERROR',
                    message: err.message,
                    failedAction: 'shared/SEND_QUESTIONNAIRE_RESPONSE',
                },
            });
        }
    },
);

const resetAnswers = createAsyncThunk(
    'shared/RESET_ANSWERS',
    async () => {
        console.log('resetting answers');
    }
);

const sendReport = createAsyncThunk(
    'shared/SEND_REPORT',
    async ({subjectId, certificate}, thunkApi) => {
        try {
            // send out report
            const response = await loggedInClient.sendReport(subjectId, certificate);
            // return data to update state
            const {
                User: {thankyou_text},
            } = thunkApi.getState();
            AlertModal.alert(
                translate('generic').successTitle,
                thankyou_text ?? translate('generic').sendSuccess,
            );
            return thunkApi.fulfillWithValue(response);
        } catch (err) {
            AlertModal.alert(
                translate('generic').errorTitle,
                translate('generic').sendError,
                [
                    {
                        text: translate('generic').ok,
                    },
                ],
                {cancelable: false},
            );
            return thunkApi.rejectWithValue({
                error: {
                    code: err.code ?? 'ERROR',
                    message: err.message,
                    failedAction: 'shared/SEND_REPORT',
                },
            });
        }
    },
);

const reset = createAsyncThunk('shared/RESET', async () => {
    if (appConfig.bleManager) {
        if (Platform.OS === 'ios') {
            // appConfig.bleManager.cancelDeviceConnection(DEVICE_ID)
            //     .catch((error) => {
            //         console.error(error);
            //         throw error;
            //     });
        }
        appConfig.bleManager.destroy();
        appConfig.bleManager = null;
        console.log('Manager destroyed');
    }

    await Storage.clear();

    if (Platform.OS === 'native') {
        [appConfig.csvFilePath, appConfig.zipFilePath].forEach((path) => {
            RNFS.exists(path)
                .then((exists) => {
                    exists ?
                        RNFS.unlink(path).catch((err) => {
                            console.error('Error while deleting path', path, err);
                            throw err
                        }) : null;
                });
        })

        PushNotification.cancelAllLocalNotifications();
    }
    isKioskMode ? kioskApi.resetKioskModeData() : null;
});

export {hideModal, sendQuestionnaireResponse, sendReport, reset, resetAnswers};
