import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Platform, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View} from 'react-native';
import PropTypes from 'prop-types';

import memoize from 'lodash.memoize';

import translate from '../../services/localization';
import {theme} from '../../config';
import DashboardButton from "../../components/survey/DashboardButton";
import {ListItem} from '@rneui/themed';
import questionnaireAnalyzer from "../../services/questionnaireAnalyzer";
import RenderHtml from "react-native-render-html";
import DeviceInfo from "react-native-device-info";
import {markdownToHtml} from "../../services/utils";
import {isMobile} from "react-device-detect";
import AlertModal from "../../components/shared/AlertModal";

/**
 * depending on the state of the given category an accessibility hint is built from the strings defined in the config file
 * @param {boolean} done whether the category has been completely answered as required
 * @param {boolean} started whether the category has been started
 * @param {boolean} seen whether the category, i.e. all its items have been seen
 * @returns {string} a string as accessibility hint describing the state of the category
 */
const getAccessibilityHint = (done, started, seen) => {
    let hint = translate('accessibility').questionnaire.categoryCellHint;
    if (done && seen) {
        return (hint +=
            translate('accessibility').questionnaire.category +
            translate('accessibility').questionnaire.finished);
    }
    if (started) {
        return (hint +=
            translate('accessibility').questionnaire.category +
            translate('accessibility').questionnaire.notFinished);
    }

    return (hint +=
        translate('accessibility').questionnaire.category +
        translate('accessibility').questionnaire.notStarted);
};

/**
 * depending on the state of the given category an accessibility hint is built from the strings defined in the config file
 * @param {boolean} done whether the category has been completely answered as required
 * @param {boolean} started whether the category has been started
 * @param {boolean} seen whether the category, i.e. all its items, have been seen
 * @returns {{name: string, color : string}} an object describing properties of the chevron
 */
const   getCategoryChevronProps = (done, started, seen) => {
    console.log('seenchevron', seen);
    console.log('seenchevron done', done);
    console.log('seenchevron started', started);

    if (done && seen) {
        return {
            name: 'check-circle',
            color: theme.values.defaultSurveyIconCompletedColor,
        };
    }
    if (started) {
        return {
            name: 'chat-question',
            color: theme.values.defaultSurveyIconTouchedColor,
        };
    }
    return {
        name: 'chat-question',
        color: theme.values.defaultSurveyIconUntouchedColor,
    };
};

/***********************************************************************************************
 * component
 * renders the list of categories, i.e. the first-level items
 *
 * @param  {object}    props
 * @param  {[QuestionnaireItem]}   props.categories indicates whether the current category has been completely answered
 * @param  {object<string, QuestionnaireItem>}   props.itemMap
 * @param  {function} props.showQuestionnaireModal callback to open the modal at the chosen category
 */
function CategoriesList({categories, itemMap, showQuestionnaireModal, FHIRmetadata, categoriesLoaded}) {
    // memoize determination of chevronProps

    const {fontScaleFactor} = useSelector(
        (state) => state.Globals
    );

    const chevronProps = memoize(
        getCategoryChevronProps,
        (done, started, seen) => `${done}_${started}_${seen}`,
    );

    // memoize determination of accessibility hint
    const a11yHint = memoize(
        getAccessibilityHint,
        (done, started, seen) => `${done}_${started}_${seen}`,
    );

    // internal state to control which category is expanded
    const [expandedCategory, setExpandedCategory] = useState(null);
    const [firstPresentation, setFirstPresentation] = useState(false);

    const toggleAccordion = (index) => {
        if (expandedCategory === index) {
            setExpandedCategory(null);
        } else {
            setExpandedCategory(index);
        }
    };

    const {lastOpenedCategoryIndex, lastOpenedPageIndex} = useSelector(
        (state) => state.Questionnaire,
    );

    const {lastSubmitSingle} = useSelector(
        (state) => state.Globals,
    );

    let [alertCanceled, setAlertCanceled] = useState(lastOpenedCategoryIndex == -1 && lastOpenedPageIndex == 0);


    useEffect(() => {
        if (!categories?.[lastOpenedCategoryIndex]?.item[0]?.fieldAnnotation?.includes('[buttonSurvey')) {
            if (!alertCanceled) {
                setAlertCanceled(true);
                AlertModal.alert(
                    translate('accessibility').questionnaire.continueFromLastTitle,
                    translate('accessibility').questionnaire.continueFromLast,
                    [
                        {
                            text: translate('accessibility').questionnaire.continueFromLastNo,
                            style: 'outlined',
                            onPress: () => {
                                setAlertCanceled(true);
                                AlertModal.hide();
                            }
                        },
                        {
                            text: translate('accessibility').questionnaire.continueFromLastYes,
                            onPress: () => {
                                setAlertCanceled(true);
                                showQuestionnaireModal(lastOpenedCategoryIndex, lastOpenedPageIndex);
                                AlertModal.hide();
                            },
                        }
                    ],
                    {cancelable: false},
                );
            }

            if (categories?.length === 1 && !firstPresentation) {
                showQuestionnaireModal(0);
                setFirstPresentation(true)
            }
        }
    });


    if (categories && categories.length > 0) {
        let i = 1;
        return (
            <View style={localStyle.wrapper}>
                {/* create accordion listItem for each category (i.e. first level item)*/}
                {categories.map((category, categoryIndex) => {
                    // get additional properties based on the completion state of the category
                    const {started: categoryStarted} =
                        itemMap[category.linkId];
                    let categorySeen = true;
                    let categoryDone = true;
                    category.item.map((categoryItem, pageIndex) => {
                        const {done: itemDone, started: itemStarted, seen: itemSeen} =
                            itemMap[categoryItem.linkId];
                        console.log('checkcondi',categoryItem.linkId, questionnaireAnalyzer.checkConditionsOfSingleItem(
                            categoryItem,
                            itemMap,
                        ))
                        if (!questionnaireAnalyzer.itemIsEmbedded(
                            categoryItem,
                            itemMap,
                        ) && questionnaireAnalyzer.checkConditionsOfSingleItem(
                            categoryItem,
                            itemMap,
                        )) {
                            categorySeen = categorySeen && itemSeen;
                            categoryDone = categoryDone &&
                                (itemDone
                                    || !itemMap[category.linkId].item[categoryItem.linkId]?.item?.required
                                    || itemMap[category.linkId].item[categoryItem.linkId]?.item?.fieldAnnotation?.includes('[skippable]')
                                );
                        }
                    });


                    //console.log('category.scheduleTimes');
                    // console.log(category.scheduleTimes);

                    const now = new Date();
                    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

                    let show = false;
                    let doFillOut = true;
                    if (!category.scheduleTimes || category.scheduleTimes.length == 0) {
                        show = true;
                    } else {
                        for (let t = 0; t < category.scheduleTimes.length; t++) {
                            let start = category.scheduleTimes[t].start;
                            let end = category.scheduleTimes[t].end;
                            const [startHour, startMinute] = start.split(':').map(Number);
                            const [endHour, endMinute] = end.split(':').map(Number);
                            let startTime = new Date(today.getTime() + startHour * 60 * 60 * 1000 + startMinute * 60 * 1000);
                            let endTime = new Date(today.getTime() + endHour * 60 * 60 * 1000 + endMinute * 60 * 1000);
                            show = show || (now <= endTime && now >= startTime);

                            if (lastSubmitSingle?.[category?.instrument_name]) {
                                if (lastSubmitSingle[category?.instrument_name] > startTime &&
                                    lastSubmitSingle[category?.instrument_name] < endTime) {
                                    doFillOut = false;
                                }
                            }

                            console.log(startTime);
                            console.log(endTime);
                        }
                    }

                    console.log(lastSubmitSingle);
                    if (category.item[0]?.fieldAnnotation.includes('[buttonSurvey')) {
                        return show ? (<>
                            <DashboardButton onPress={() => showQuestionnaireModal(categoryIndex)}
                                             solid={category.scheduleTimes?.length > 0 && doFillOut}
                                             text={
                                                 !!FHIRmetadata.hideTitlesInOverview ? 'Fragebogen ' + (i++) : category.text
                                             }/>
                            {category.scheduleTimes?.length > 0 && doFillOut &&
                                <Text style={{textAlign: 'center', fontSize: fontScaleFactor * 12}}>Bitte jetzt
                                    ausfüllen</Text>}
                            {
                                !!lastSubmitSingle?.[category?.instrument_name] &&
                                <Text style={{textAlign: 'center', fontSize: fontScaleFactor * 12}}>Zuletzt
                                    ausgefüllt: {lastSubmitSingle?.[category?.instrument_name]?.toLocaleString('de-DE', {timeZone: 'UTC'})}</Text>
                            }
                            {
                                !lastSubmitSingle?.[category?.instrument_name] &&
                                <Text style={{textAlign: 'center', fontSize: fontScaleFactor * 12}}>Bislang nicht
                                    ausgefüllt</Text>
                            }
                        </>) : (<></>)
                    } else {
                        const {width} = useWindowDimensions();

                        return (
                            <ListItem.Accordion
                                content={
                                    <TouchableOpacity
                                        style={localStyle.accordionContent}
                                        onPress={() => showQuestionnaireModal(categoryIndex)}
                                    >
                                        <ListItem.Chevron
                                            type="material-community"
                                            name={chevronProps(categoryDone, categoryStarted, categorySeen).name}
                                            size={18}
                                            reverse
                                            color={chevronProps(categoryDone, categoryStarted, categorySeen).color}
                                            testID={`${category.linkId}_icon`}
                                        />
                                        <ListItem.Content>
                                            {!!FHIRmetadata.hideTitlesInOverview &&
                                                <ListItem.Title style={{
                                                    ...localStyle.titleStyle,
                                                    fontSize: localStyle.titleStyle.fontSize * fontScaleFactor
                                                }}>
                                                    Fragebogen {i++}
                                                </ListItem.Title>
                                            }
                                            {!FHIRmetadata.hideTitlesInOverview &&
                                                <RenderHtml
                                                    staticContentMaxWidth={width}
                                                    contentWidth={width}
                                                    source={{
                                                        html: '<div style="font-size: ' + (DeviceInfo.isTablet() ? 1.75 : 1.2) * fontScaleFactor + 'em">' + markdownToHtml(category.text) + '</div>'
                                                    }}
                                                />
                                            }
                                        </ListItem.Content>
                                    </TouchableOpacity>
                                }
                                isExpanded={categoryIndex === expandedCategory || category.item[0]?.fieldAnnotation?.includes('[showquestionlist|unanswered]')}
                                key={category.linkId}
                                containerStyle={localStyle.accordionContainer}
                                icon={category.item[0]?.fieldAnnotation?.includes('[showquestionlist]') ? {
                                    name: 'expand-more',
                                    size: 30,
                                    onPress: () => toggleAccordion(categoryIndex),
                                    accessibilityHint:
                                        categoryIndex !== expandedCategory
                                            ? translate('accessibility').questionnaire.expandCategory
                                            : translate('accessibility').questionnaire.collapseCategory,
                                    accessibilityRole: translate('accessibility').types.button,
                                } : false}
                                accessibilityLabel={category.text}
                                accessibilityRole={translate('accessibility').types.button}
                                accessibilityHint={a11yHint(categoryDone, categoryStarted, categorySeen)}
                            >
                                {/* when category is expanded list items of that category */}
                                {(categoryIndex === expandedCategory || category.item[0]?.fieldAnnotation?.includes('[showquestionlist|unanswered]')) &&
                                    category.item.map((categoryItem, pageIndex) => {
                                        const {done: itemDone, started: itemStarted, seen: itemSeen} =
                                            itemMap[categoryItem.linkId];
                                        const itemText = categoryItem.type != 'display' ?
                                            categoryItem.text.replace(/(<([^>]+)>)/ig, '') : "(Anleitung)";
                                        // only display item when dependencies are met

                                        if (category.item[0]?.fieldAnnotation?.includes('[showquestionlist|unanswered]') && (!categoryStarted || itemDone || categoryItem.type === 'display')) {
                                            return null;
                                        }
                                        return questionnaireAnalyzer.checkConditionsOfSingleItem(
                                            categoryItem,
                                            itemMap,
                                        ) && !questionnaireAnalyzer.itemIsEmbedded(
                                            categoryItem,
                                            itemMap,
                                        ) ? (
                                            <ListItem
                                                key={categoryItem.linkId}
                                                containerStyle={[localStyle.listItemContainer]}
                                                onPress={() =>
                                                    showQuestionnaireModal(categoryIndex, pageIndex + 1)
                                                }
                                            >
                                                {categoryItem.type != 'display' ?
                                                    <ListItem.Chevron
                                                        type="material-community"
                                                        name={chevronProps(itemDone, itemStarted, itemSeen).name}
                                                        size={30}
                                                        color={chevronProps(itemDone, itemStarted, itemSeen).color}
                                                        testID={`${category.linkId}_icon`}
                                                    /> : <ListItem.Chevron
                                                        type="material-community"
                                                        size={30}
                                                        name={'file-document'}
                                                        color={theme.colors.success}
                                                    />}
                                                {/* title */}
                                                <ListItem.Content>
                                                    <RenderHtml
                                                        staticContentMaxWidth={width}
                                                        contentWidth={width}
                                                        source={{
                                                            html:
                                                                '<div style="font-size: ' + (DeviceInfo.isTablet() ? 1.75 : 1.2) * fontScaleFactor + 'em">' + markdownToHtml(itemText) +
                                                                (categoryItem.required ? '<br /><span style="color: #ff0000; ">(Pflichtfrage)</span>' : '') +
                                                                '</div>'
                                                        }}
                                                    />
                                                </ListItem.Content>
                                            </ListItem>
                                        ) : null;
                                    })}
                            </ListItem.Accordion>
                        );
                    }
                })}
            </View>
        );
    } else {
        return <View style={{...localStyle.wrapper, paddingLeft: 20, paddingRight: 20}}>
            <Text>Aktuell stehen keine weiteren Fragebögen für Sie zur Verfügung.</Text>
        </View>;
    }

}

CategoriesList.propTypes = {
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            linkId: PropTypes.string,
            text: PropTypes.string.isRequired,
        }),
    ),
    itemMap: PropTypes.objectOf(
        PropTypes.shape({linkId: PropTypes.string, text: PropTypes.string}),
    ),
    showQuestionnaireModal: PropTypes.func.isRequired,
};

CategoriesList.defaultProps = {
    categories: null,
    itemMap: null,
};

const localStyle = StyleSheet.create({
    wrapper: {
        alignSelf: 'center',
        alignContent: 'center',
        width: Platform.OS === 'web' && isMobile ? '100%' : 1000,
        flexDirection: 'column',
        backgroundColor: theme.values.defaultBackgroundColor,
    },

    accordionContainer: {
        backgroundColor: theme.values.defaultSurveyItemBackgroundColor,
        width: '100%',
    },
    accordionContent: {
        flex: 1,
        paddingLeft: 0,
        flexDirection: 'row',
        backgroundColor: theme.values.defaultSurveyItemBackgroundColor,
    },

    listItemContainer: {
        width: '100%',
        borderBottomColor: theme.colors.accent1,
        borderBottomWidth: 1,
        backgroundColor: theme.values.defaultSurveyItemBackgroundColor,
        paddingLeft: 17,
    },

    titleStyle: {
        ...theme.fonts.header2,
        paddingLeft: 14,
        color: theme.values.defaultSurveyItemTitleColor,
    },

    itemTitleStyle: {
        ...theme.fonts.header3,
        color: theme.values.defaultSurveyItemTitleColor,
    },
});

export default CategoriesList;
