import jsQR from 'jsqr';

var video = document.createElement('video');
video.crossOrigin = "anonymous";
video.autoplay = true;
video.playsinline = true;
video.muted = true;
var canvasElement;
var canvas;
var loadingMessage;
var outputContainer;
var outputMessage;
var outputData;

let videoStream;

export function qrCodeScanningLogic(setQRCodes, setVideoStream) {
    canvasElement = document.getElementById('canvas');
    canvas = canvasElement.getContext('2d');
    loadingMessage = document.getElementById('loadingMessage');
    outputContainer = document.getElementById('output');
    outputMessage = document.getElementById('outputMessage');
    outputData = document.getElementById('outputData');

    const tick = () => {
        loadingMessage.innerText = '⌛ Loading video...';
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
            loadingMessage.hidden = true;
            canvasElement.hidden = false;
            outputContainer.hidden = false;

            canvasElement.height = video.videoHeight;
            canvasElement.width = video.videoWidth;
            canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
            var imageData = canvas.getImageData(
                0,
                0,
                canvasElement.width,
                canvasElement.height,
                ['willReadFrequently'],
            );
            var code = jsQR(imageData.data, imageData.width, imageData.height, {
                inversionAttempts: 'dontInvert',
            });
            if (code && code.data) {
                drawLine(
                    code.location.topLeftCorner,
                    code.location.topRightCorner,
                    '#FF3B58',
                );
                drawLine(
                    code.location.topRightCorner,
                    code.location.bottomRightCorner,
                    '#FF3B58',
                );
                drawLine(
                    code.location.bottomRightCorner,
                    code.location.bottomLeftCorner,
                    '#FF3B58',
                );
                drawLine(
                    code.location.bottomLeftCorner,
                    code.location.topLeftCorner,
                    '#FF3B58',
                );
                outputMessage.hidden = true;
                outputData.parentElement.hidden = false;
                outputData.innerText = code.data;
                setQRCodes((previousqrcodes) => [...previousqrcodes, code.data]);
                console.log('QR Code detected', code.data);
            } else {
                outputMessage.hidden = false;
                outputData.parentElement.hidden = true;
            }
        }
        requestAnimationFrame(tick);
    };

    let timer = setTimeout(() => {
        navigator.mediaDevices
            .getUserMedia({video: {facingMode: 'environment'}})
            .then(function (stream) {
                setVideoStream(stream);
                videoStream = stream;
                video.srcObject = stream;
                video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
                video.play();
                requestAnimationFrame(tick);
            })
            .catch(function (err) {
                console.log(err);
                deactivateMediaStream();
                reactivateMediaStream();
            });
    }, 2000);

    // Function to reactivate the video stream
    function reactivateMediaStream() {
        if (!videoStream) {

            navigator.mediaDevices
                .getUserMedia({video: {facingMode: 'environment'}})
                .then(function (stream) {
                    setVideoStream(stream);
                    videoStream = stream;
                    video.srcObject = stream;
                    video.play();
                    requestAnimationFrame(tick);
                })
                .catch(function (error) {
                    console.error('Error accessing webcam:', error);
                    deactivateMediaStream();
                });
        }
    }

    return timer;
}

function drawLine(begin, end, color) {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
}

export function deactivateMediaStream() {
    if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop());
    }
}
