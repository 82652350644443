import React from 'react';
import {Platform, Text, View} from 'react-native';
import {Dialog} from "react-native-simple-dialogs";
import {Button} from "react-native-paper";
import {isMobile} from "react-device-detect";
import {reduxStore} from '../store';
import {hideAlert} from '../store/alert.slice';
import {theme} from '../config';
import {useSelector} from "react-redux";


const AlertDialog = ({
                         visible,
                         onDismiss,
                         title = "Alert",
                         message = "This is an alert message.",
                         dialogButtons = []
                     }) => {
    const fontScaleFactor = useSelector(
        (state) => state.Globals.fontScaleFactor,
    );

    return (
        <Dialog
            visible={visible}
            title={title}
            titleStyle={{...theme.fonts.header2, fontSize: theme.fonts.header2.fontSize * fontScaleFactor}}
            onTouchOutside={() => {
            }}
            dialogStyle={{
                width: Platform.OS === 'web' && isMobile ? '95%' : 800,
                alignSelf: 'center',
                flexWrap: 'wrap'
            }}
        >
            <Text style={{
                marginBottom: 20,
                ...theme.fonts.body,
                fontSize: theme.fonts.body.fontSize * fontScaleFactor,
                lineHeight: theme.fonts.body.lineHeight * fontScaleFactor
            }}>{message}</Text>
            <View style={{flexDirection: 'row', flexWrap: 'wrap', width: '100%', alignContent: 'space-between'}}>
                {dialogButtons.map((button) => (
                    <Button
                        mode={button.style ? button.style : 'contained'}
                        onPress={() => {
                            button.onPress ? button.onPress() : null;
                        }}
                        style={{
                            marginRight: 10, marginBottom: 5,
                        }}
                        labelStyle={{
                            ...theme.fonts.body,
                            fontSize: theme.fonts.body.fontSize * fontScaleFactor,
                            lineHeight: theme.fonts.body.lineHeight * fontScaleFactor
                        }}
                    >
                        {button.text}
                    </Button>
                ))}
                {!dialogButtons && <Button
                    mode={'contained'}
                    onPress={() => {
                        reduxStore.dispatch(hideAlert());
                    }}

                    labelStyle={{
                        ...theme.fonts.body,
                        fontSize: theme.fonts.body.fontSize * fontScaleFactor,
                        lineHeight: theme.fonts.body.lineHeight * fontScaleFactor
                    }}
                    style={{
                        marginRight: 5
                    }}>OK</Button>}
            </View>
        </Dialog>
    );
};

export default AlertDialog;
