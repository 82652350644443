import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {guestClient} from '../services/rest';
import kioskApi from '../config/kioskApiConfig';
import {setAvailableLanguages} from '../services/localization';

import {sendCredentials, updateLanguage, updateUser} from './user.slice';
import {fetchQuestionnaire} from './questionnaire.slice';
import {reset, resetAnswers, sendQuestionnaireResponse, sendReport} from './sharedActions';

// services & config

const getLanguages = createAsyncThunk(
    'globals/GET_LANGUAGES',
    async (_, thunkApi) => {
        const languages = await (kioskApi.active
            ? kioskApi.getLanguages()
            : guestClient.getLanguages());
        setAvailableLanguages(languages);
        return thunkApi.fulfillWithValue(languages);
    },
);

const setBLEBatteryLevel = createAsyncThunk(
    'globals/SET_BLE_BATTERY_LEVEL',
    async (batteryLevel, thunkApi) => {
        return thunkApi.fulfillWithValue(batteryLevel);
    },
);

const setBLEBufferSize = createAsyncThunk(
    'globals/SET_BUFFER_SIZE',
    async (bufferSize, thunkApi) => {
        return thunkApi.fulfillWithValue(bufferSize);
    },
);

const setNewVersionURL = createAsyncThunk(
  'globals/SET_NEW_VERSION_URL',
  async (newVersionURL, thunkApi) => {
    return thunkApi.fulfillWithValue(newVersionURL);
  },
);


const setBLErssi = createAsyncThunk(
    'globals/SET_BLE_RSSI',
    async (batteryLevel, thunkApi) => {
        return thunkApi.fulfillWithValue(batteryLevel);
    },
);

const setBLEDevice = createAsyncThunk(
    'globals/SET_BLE_DEVICE',
    async (device, thunkApi) => {
        console.log('setting connected BLE device');
        console.log(device)
        console.log('device id')
        console.log(device.id)
        console.log(device.name)
        return thunkApi.fulfillWithValue(device);
    },
);

const setBLEDeviceState = createAsyncThunk(
    'globals/SET_BLE_DEVICE_STATE',
    async (batteryLevel, thunkApi) => {
        return thunkApi.fulfillWithValue(batteryLevel);
    },
);

const setFontScaleFactor = createAsyncThunk(
    'globals/SET_FONT_SCALE_FACTOR',
    async (fontScaleFactor, thunkApi) => {
        return thunkApi.fulfillWithValue(fontScaleFactor);
    },
);


/**
 * this part of the global state handles the loading state and all errors (mostly network related) that might occur
 */
const initialState = {
    loading: false,
    error: null,
    availableLanguages: null,
    BLEBatteryLevel: null,
    BLEdevice: null,
    BLEdeviceState: null,
    BLErssi: null,
    BLEbufferSize: null,
    lastSubmitSingle: {},
    fontScaleFactor: 1
}
const GlobalsSlice = createSlice({
    name: 'globals',
    initialState: initialState,
    reducers: {
        INIT: (state) => ({...initialState}),
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendCredentials.pending, (state) => ({
                ...state,
                loading: true,
            }))
            .addCase(sendCredentials.fulfilled, (state) => ({
                ...state,
                loading: false,
                error: null,
            }))
            .addCase(sendCredentials.rejected, (state, action) => ({
                ...state,
                loading: false,
                error: action.payload.error,
            }))
            .addCase(updateUser.pending, (state) => ({
                ...state,
                loading: true,
            }))
            .addCase(updateUser.fulfilled, (state) => ({
                ...state,
                loading: false,
                error: null,
            }))
            .addCase(updateUser.rejected, (state, action) => ({
                ...state,
                loading: false,
                error: action.payload.error,
            }))
            .addCase(updateLanguage.pending, (state) => ({...state, loading: true}))
            .addCase(updateLanguage.fulfilled, (state) => ({
                ...state,
                loading: false,
                error: null,
            }))
            .addCase(updateLanguage.rejected, (state, action) => ({
                ...state,
                loading: false,
                error: action.payload.error,
            }))
            .addCase(fetchQuestionnaire.pending, (state) => ({
                ...state,
                loading: true,
            }))
            .addCase(fetchQuestionnaire.fulfilled, (state) => ({
                ...state,
                loading: false,
                error: null,
            }))
            .addCase(fetchQuestionnaire.rejected, (state, action) => ({
                ...state,
                loading: false,
                error: action.payload.error,
            }))
            .addCase(sendQuestionnaireResponse.pending, (state) => ({
                ...state,
                loading: true,
            }))
            .addCase(sendQuestionnaireResponse.fulfilled, (state, action) => {
                let lss = {
                    ...state.lastSubmitSingle
                };
                if (action.payload.instrument_name) {
                    lss[action.payload.instrument_name] = new Date();
                }

                return {
                    ...state,
                    lastSubmitSingle: lss,
                    loading: false,
                    error: null,
                }
            })
            .addCase(resetAnswers.fulfilled, (state) => ({
                ...state,
                loading: false,
                error: null,
            }))
            .addCase(sendQuestionnaireResponse.rejected, (state, action) => {
                return {
                    ...state,
                    loading: false,
                    error: action.payload ? action.payload.error : action.error,
                };
            })
            .addCase(reset.pending, (state) => ({
                ...state,
                loading: true,
            }))
            .addCase(reset.rejected, (state, action) => ({
                ...state,
                loading: false,
                error: action?.payload?.error,
            }))
            .addCase(reset.fulfilled, (_state) => ({
                ...initialState
            }))
            .addCase(sendReport.pending, (state) => ({...state, loading: true}))
            .addCase(sendReport.fulfilled, (state) => ({
                ...state,
                loading: false,
                error: null,
            }))
            .addCase(sendReport.rejected, (state, action) => ({
                ...state,
                loading: false,
                error: action.payload.error,
            }))
            .addCase(getLanguages.fulfilled, (state, action) => ({
                ...state,
                availableLanguages: action.payload,
            }))
            .addCase(setBLEBatteryLevel.fulfilled, (state, action) => ({
                ...state,
                BLEBatteryLevel: action.payload,
            }))
            .addCase(setBLEDeviceState.fulfilled, (state, action) => ({
                ...state,
                BLEdeviceState: action.payload,
            }))
            .addCase(setBLEDevice.fulfilled, (state, action) => ({
                ...state,
                BLEdevice: action.payload,
            }))
            .addCase(setBLErssi.fulfilled, (state, action) => ({
                ...state,
                BLErssi: action.payload,
            }))

            .addCase(setBLEBufferSize.fulfilled, (state, action) => ({
                ...state,
                BLEbufferSize: action.payload,
            }))
            .addCase(setFontScaleFactor.fulfilled, (state, action) => ({
                ...state,
                fontScaleFactor: action.payload,
            }))
            .addDefaultCase((state) => ({...state}));
    },
});

export default GlobalsSlice.reducer;
export {getLanguages, setBLEBatteryLevel, setBLEDevice, setBLEDeviceState, setBLErssi, setBLEBufferSize, setNewVersionURL, setFontScaleFactor};
export const {INIT: init} = GlobalsSlice.actions;
