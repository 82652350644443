import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
    name: 'alert',
    initialState: {
        alertVisible: false,
        alertTitle: '',
        alertMessage: '',
        alertButtons: []
    },
    reducers: {
        showAlert: (state, action) => {
            state.alertVisible = true;
            state.alertTitle = action.payload.title;
            state.alertMessage = action.payload.message;
            state.alertButtons = action.payload.alertButtons;
        },
        hideAlert: (state) => {
            state.alertVisible = false;
            state.alertTitle = '';
            state.alertMessage = '';
            state.alertButtons = [];
        },
    },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;
